<template>
	<v-card flat class="mw-title-wrapper">
		<v-divider class="mt-2 mb-5" v-if="question.show_title"></v-divider>
		<v-row
			class="mw-question-toolbar mt-5 mb-3"
			:collapse="question.show_title"
			:absolute="question.show_title"
			:style="question.show_title ? rightStyle : {}"
		>
			<v-col cols="10">
				<div style="display: inline">
					<span class="text-h6" v-if="question.show_title" v-html="`${question.question_text}`"></span>
					<template v-if="question.subquestion_text">
						<br/>
						<span class="subtitle-1"
							v-html="question.subquestion_text"
						></span>
					</template>
				</div>
				<template v-if="question.tooltip">
					<v-tooltip left allow-overflow max-width="280">
						<template v-slot:activator="{on}">
							<v-icon tabindex="-1" class="ml-2" v-on="on"
								>mdi-information-outline</v-icon
							>
						</template>
						{{ question.tooltip }}
					</v-tooltip>
				</template>

				<question-badge
					v-if="question.badge"
					:value="question.badge"
				></question-badge>
			</v-col>
			<v-col cols="2">
				<div class="ml-2 mb-5">
					<template v-if="canPoll">
						<poll :id="id"></poll>
					</template>
				</div>
			</v-col>
		</v-row>
		<v-card-text v-if="question.content">
			<div v-html="question.content"></div>
		</v-card-text>
	</v-card>
</template>

<style lang="less">
	.mw-question-toolbar {
		@media screen and (min-width: 768px) {
			font-size: 1.1em;
		}
		@media screen and (max-width: 768px) {
			height: auto !important;
			font-size: 0.8em;
			margin-top: 20px;
			margin-bottom: 60px;
			.v-toolbar__content {
				height: auto !important;
			}
		}
		margin-bottom: 15px;
		.v-toolbar__content {
			padding-left: 0px !important;
		}
		span {
			max-width: 90%;
		}
	}
</style>
<script>
	import Poll from "@c/collaboration/Poll";
	import QuestionBadge from "@c/survey/render/QuestionBadge";
	export default {
		name: "QuestionTitle",
		props: {
			id: {type: String},
			staffSurvey: {type: Boolean},
			poll: {type: Boolean}
		},
		data: () => {
			return {};
		},
		computed: {
			question() {
				return this.$store.state.questions.data[this.id];
			},
			canPoll() {
				return this.$store.getters.canPoll && !this.question.hide_polling;
			},
			questionTemplate() {
				if (!this.question) {
					return;
				}
				var id = this.question.question_template;
				return this.$store.state.questionTemplates.data[id];
			},
			questionTexts() {
				return this.$store.getters["questions/getQuestionTexts"](this.id);
			},
			tooltip() {
				return this.question.help_text || false;
			},
			rightStyle() {
				return {
					right: 0
				};
			}
		},
		components: {
			Poll,
			QuestionBadge
		}
	};
</script>
