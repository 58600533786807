<template>
	<v-sheet>
		
		<div v-html="$t('collaboration.polls.before_poll')"></div>
		<div v-html="$t('collaboration.polls.poll_participants')"></div>
		<v-simple-table>
			<tbody>
				<pollee-list-item v-for="(polls, user) in pollsForUsers" :key="user" :user="user" :polls="polls">
				</pollee-list-item>
			</tbody>
		</v-simple-table>
	</v-sheet>
</template>

<script>
	import PolleeListItem from "@c/collaboration/PolleeListItem";
	export default {
		name: "ExistingPollees",
		props: {
			polls: Array
		},
		components: {
			PolleeListItem
		}, 
		computed: {
			pollsForUsers(){
				const self = this;
				let users = {};
				self.polls.forEach( poll => {
					if( !users[poll.user] ) {
						users[poll.user] = [];
					}
					users[poll.user].push( poll );
				} );
				return users;
			}
		}
	};
</script>
