<template>
	<tr v-if="userObj" class="pl-0">
		<td>
			<p>
				{{ userObj.name }}<br />
				{{ userObj.email }}<br />
				<span v-if="userObj.updated_at">
					<i>
						Active: {{ userObj.updated_at.toDate() | moment("Do MMMM YYYY") }}
					</i>
				</span>
			</p>
		</td>
		<td>
			<b>{{ answer }}</b>
		</td>
		<td>
			<remind-colleague v-model="user" icon></remind-colleague>
		</td>
	</tr>
</template>

<style lang="less"></style>
<script>
	import _ from "lodash";
	import RemindColleague from "@c/collaboration/RemindColleague";
	export default {
		name: "PolleeListItem",
		props: {
			polls: Array,
			user: String
		},
		components: {
			RemindColleague
		},
		computed: {
			poll() {
				return this.polls.find((poll) => poll.data);
			},
			userObj() {
				return this.$store.state.users.data[this.user];
			},
			question() {
				if (!this.poll) {
					return;
				}
				return this.$store.state.questions.data[this.poll.question];
			},
			questionTemplate() {
				if (!this.question) {
					return;
				}
				return this.$store.state.questionTemplates.data[
					this.question.question_template
				];
			},
			answer() {
				const self = this;
				if (!self.questionTemplate || !self.poll.data) {
					return;
				}
				let data = _.isArray(self.poll.data)
					? self.poll.data
					: [self.poll.data];
				return data.map((v) => self.getAnswer(v)).join(", ");
			},
			initials() {
				return this.userObj.name
					.split(" ")
					.map((a) => a.charAt(0))
					.join("");
			}
		},
		methods: {
			getAnswer(value) {
				return this.questionTemplate.answer_options[value]
					? this.questionTemplate.answer_options[value].text
					: null;
			}
		}
	};
</script>
