<template>
	<v-tooltip top v-if="badge">
		<template v-slot:activator="{on}">
			<v-chip tabindex="-1" 
				v-on="on"
				:color="badge.color ? badge.color : 'primary'"
				:href="badge.url"
                class="pr-4 mt-2"
            >
				{{ badge.title }}
			</v-chip>
		</template>
		{{ badge.mouseover }}
	</v-tooltip>
</template>

<script>
	export default {
		name: "QuestionBadge",
		props: {
			value: String
		},
		computed: {
			badge() {
				return this.$store.state.badges.data[this.value];
			}
		}
	};
</script>
