<template>
	<div>
		<v-menu offset-y>
			<template v-slot:activator="{on}">
				<v-btn icon v-on="on" tabindex="-1" >
					<v-icon>mdi-comment-account</v-icon>
					<v-badge
						v-if="polls.length"
						small
						color="success"
						:content="polls.length"
					></v-badge>
				</v-btn>
			</template>
			<v-list dense>
				<v-list-item v-if="polls.length" @click="dialog = true">
					<v-list-item-title>Existing Polls</v-list-item-title>
				</v-list-item>
				<v-list-item @click="newPoll">
					<v-list-item-title>New Poll</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>
		<mw-dialog
			v-model="dialog"
			large
			:title="$t('collaboration.polls.title')"
		>
			<template v-slot:dialog-body>
				<existing-pollees :polls="polls"></existing-pollees>
			</template>
			<template v-slot:dialog-buttons>
				<v-btn @click="close" color="primary">Done</v-btn>
			</template>

		</mw-dialog>
	</div>
</template>

<script>
	import MwDialog from "@c/ui/MwDialog";
	import ExistingPollees from "@c/collaboration/ExistingPollees";

	export default {
		name: "Poll",
		props: {
			id: {type: String} // the ID of the question
		},
		data: () => {
			return {
				dialog: false
			};
		},
		computed: {
			polls() {
				return this.$store.getters["polls/byQuestion"](this.id);
			}
		},
		components: {
			MwDialog,
			ExistingPollees
		},
		methods: {
			close() {
				this.dialog = false;
			},
			newPoll() {
				this.close();
				this.$root.$emit("newPoll", this.id);
			}
		}
	};
</script>
